// When the customer clicks on the button, redirect
// them to Checkout.
function stripePayment(product, email) {
    var stripe = Stripe('pk_live_77KM68lmwq6e1D0fqx1TW2nN005amLMYoW');

    stripe.redirectToCheckout({
        items: [{ sku: product, quantity: 1 }],

        // Do not rely on the redirect to the successUrl for fulfilling
        // purchases, customers may not always reach the success_url after
        // a successful payment.
        // Instead use one of the strategies described in
        // https://stripe.com/docs/payments/checkout/fulfillment
        customerEmail: email,
        successUrl: 'https://www.movingdev.com/shop/success',
        cancelUrl: 'https://www.movingdev.com/shop'
    })
        .then(function (result) {
            if (result.error) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer.
                var displayError = document.getElementById('error-message');
                displayError.textContent = result.error.message;
            }
        });
}